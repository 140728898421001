import axios from "../instance";

export default {
  getAll: (companyId) => {
    return axios.api
      .get(`/purchaseOrder?companyId=${companyId}`)
      .then((response) => response.data);
  },
  getAllByStatus: (status, status2, status3) => {
    if (status && !status2 && !status3) {
      return axios.api
        .get(`/purchaseOrder?status=${status}`)
        .then((response) => response.data);
    }

    if (status && status2 && !status3) {
      return axios.api
        .get(`/purchaseOrder?status=${status}&status=${status2}`)
        .then((response) => response.data);
    }

    if (status && status2 && status3) {
      return axios.api
        .get(
          `/purchaseOrder?status=${status}&status=${status2}&status=${status3}`
        )
        .then((response) => response.data);
    }
  },
  getOne: (id) => {
    return axios.api
      .get(`/purchaseOrder/${id}`)
      .then((response) => response.data);
  },
  search: (body, statusObj) => {
    let [status, status2, status3] = statusObj;

    if (status && !status2 && !status3) {
      return axios.api
        .post(`/purchaseOrder/search?status=${status}`, body)
        .then((response) => response.data);
    }

    if (status && status2 && !status3) {
      return axios.api
        .post(`/purchaseOrder/search?status=${status}&status=${status2}`, body)
        .then((response) => response.data);
    }

    if (status && status2 && status3) {
      return axios.api
        .post(
          `/purchaseOrder/search?status=${status}&status=${status2}&status=${status3}`,
          body
        )
        .then((response) => response.data);
    }
  },
  create: (body) => {
    return axios.api
      .post("/purchaseOrder", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/purchaseOrder/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/purchaseOrder/${id}`)
      .then((response) => response.data);
  },

  // items
  deleteItems: (body) => {
    return axios.api
      .post(`/purchaseOrder/deleteItems`, body)
      .then((response) => response.data);
  },
  updateItem: (id, body) => {
    return axios.api
      .put(`/purchaseOrder/item/${id}`, body)
      .then((response) => response.data);
  },
};
