<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">เพิ่มรายการสั่งซื้อสินค้า</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
              id="closeBtn"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <form id="formItems" class="needs-validation" novalidate>
              <div class="row d-flex justify-content-center mb-4">
                <label class="col-sm-10 text-start">เลขที่เอกสาร</label>
                <div class="col-sm-10">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>
              <div class="row d-flex justify-content-center text-start mb-4">
                <label for="input1  form-label" class="col-sm-10"
                  >ค้นหาสินค้า</label
                >
                <div class="col-sm-5">
                  <input
                    v-model="searchProduct"
                    @input="
                      searchProduct ? (isValid = true) : (isValid = false)
                    "
                    type="text"
                    class="form-control"
                    :style="!isValid ? 'border-color: red' : 'border-color: '"
                    id="input1"
                  />
                  <div v-if="!isValid" style="color: red" class="text-end">
                    กรุณากรอก
                  </div>
                </div>

                <div class="col-sm-3 mb-3">
                  <div
                    class="form-check form-check-inline form-check-custom form-check-solid mt-3"
                  >
                    <input
                      v-model="searchBy"
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      value="code"
                      id="flexRadioChecked"
                    />
                    <label
                      class="form-check-label me-4"
                      for="flexRadioChecked"
                      :style="isSubmit ? 'color: black' : ''"
                      >Item no.</label
                    >
                    <input
                      v-model="searchBy"
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      value="barcode"
                      id="flexRadioChecked2"
                      checked="checked"
                    />
                    <label
                      class="form-check-label"
                      for="flexRadioChecked"
                      :style="isSubmit ? 'color: black' : ''"
                      >Barcode</label
                    >
                  </div>
                </div>

                <div class="col-md-2">
                  <button
                    type="button"
                    class="btn btn-sm btn-light-primary"
                    style="font-size: 14px; font-weight: bold; width: 100%"
                    width="100%"
                    @click="search"
                  >
                    ค้นหา
                  </button>
                </div>
              </div>

              <div class="row d-flex justify-content-center text-start mb-4">
                <label class="col-sm-10">Item no</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.product_code"
                    @input="
                      form.product_code ? (isValid = true) : (isValid = false)
                    "
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>
              <div class="row d-flex justify-content-center text-start mb-4">
                <label class="col-sm-10">Item name</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.product_name"
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>
              <div class="row d-flex justify-content-center text-start mb-4">
                <label class="col-sm-10">Barcode</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.product_barcode"
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center text-start">
                <div class="col-sm-5">
                  <label class="">On Hand</label>
                  <input
                    v-model="form.product_bring_qty"
                    disabled
                    type="text"
                    class="form-control mb-4"
                    id="inputGroupFile01"
                  />
                </div>
                <div class="col-sm-5">
                  <label class="">In Transit</label>
                  <input
                    v-model="form.product_in_transit"
                    disabled
                    type="text"
                    class="form-control mb-4"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center text-start">
                <div class="col-sm-5">
                  <label class="">Min</label>
                  <input
                    v-model="form.product_min_stock"
                    disabled
                    type="text"
                    class="form-control mb-4"
                    id="inputGroupFile01"
                  />
                </div>
                <div class="col-sm-5">
                  <label class="">Max</label>
                  <input
                    v-model="form.product_max_stock"
                    disabled
                    type="text"
                    class="form-control mb-4"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center text-start">
                <label class="col-sm-10">มูลค่าต่อหน่วย</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.product_cost_price"
                    disabled
                    type="text"
                    class="form-control mb-4"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center text-start">
                <label class="col-sm-10 required">จำนวนหน่วย</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.item_amt"
                    @input="
                      form.item_amt ? (isValid = true) : (isValid = false)
                    "
                    type="number"
                    class="form-control mb-4"
                    id="inputGroupFile01"
                    required
                    :style="!isValid ? 'border-color: red' : 'border-color: '"
                  />
                  <div v-if="!isValid" style="color: red" class="text-end">
                    กรุณากรอก
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-center text-start">
                <label class="col-sm-10">ส่วนลดต่อหน่วย</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.item_discount"
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>
            </form>
          </div>

          <hr class="pt-0 mt-0" style="color: LightGrey" />

          <footer class="mt-2 mb-6 ms-7 row d-flex justify-content-center">
            <div
              class="card-toolbar ps-0 col-sm-10 d-flex justify-content-start"
            >
              <button
                @click="submit"
                type="button"
                class="btn btn-primary me-3"
              >
                บันทึก
              </button>
              <button
                @click="close"
                type="button"
                id="closeDialog"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                ยกเลิก
              </button>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

export default {
  props: {
    dialogAddItem: Boolean,
    dataEditItem: Object,
  },
  data: () => ({
    isSubmit: false,
    isValid: true,

    searchBy: "barcode",
    searchProduct: "",

    form: {
      //new
      selectMainProduct: "",
      selectSubProduct: "",

      product_name: "",
      product_desc: "",
      product_barcode: "",
      product_pog: "",
      product_cost_price: "",
      product_wholesale_price: "",
      product_retail_price: "",
      product_promotion_price: "",
      product_min_stock: "",
      product_max_stock: "",
      product_bring_qty: "",
      status: "0",
      approved_by: "",
      product_expire: "",

      item_amt_recieve: "",
      item_amt: "",
      item_discount: "",

      //old
      itemNo: "",
      itemDetail: "",
      barcode: "",
      accountingPeriod: "",
      docNumber: "",
      amount: "",
      pricePerUnit: "",
      discountPerUnit: "",
      vat: "",
      receiveDate: "",
      consignee: "",
      warehouseTo: "",
    },
  }),
  watch: {
    dialogAddItem(val) {
      if (val) {
        this.isValid = true;
        document.getElementById("button-open-dialog").click();
        if (this.dataEditItem) {
          this.form = this.dataEditItem;
          this.searchProduct = this.dataEditItem.product_barcode;
        }
      }
    },
  },
  methods: {
    async submit() {
      this.checkFormIsEmpty();

      if (this.isValid) {
        this.form = {
          ...this.form,
          item_amt_recieve: this.form.item_amt,
          item_amt_old_recieve: 0,
          item_amt_new_recieve: 0,
          item_vat:
            (parseFloat(this.form.item_amt) *
              parseFloat(this.form.product_cost_price) *
              7) /
            100,
          item_total_before_vat:
            parseFloat(this.form.item_amt) *
            parseFloat(this.form.product_cost_price),
        };
        if (
          Object.keys(this.dataEditItem).length === 0 &&
          this.dataEditItem.constructor === Object
        ) {
          await this.$emit("submitAddItem", this.form);
        } else {
          await this.$emit("submitEditItem", this.form);
        }
        this.clearForm();
        this.close();
        document.getElementById("closeDialog").click();
      }
    },
    checkFormIsEmpty() {
      !this.form.item_amt
        ? (this.isValid = false)
        : !this.form.product_code
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    close() {
      this.$emit("closeDialogAddItem");
    },
    async search() {
      const companyId = localStorage.getItem("companyId");
      if (this.searchBy === "code") {
        await this.searchByCode(companyId, this.searchProduct);
      }
      if (this.searchBy === "barcode") {
        await this.searchByBarcode(companyId, this.searchProduct);
      }
    },
    async searchByCode(companyId, productCode) {
      let responseData = [];
      try {
        responseData = await whApi.product.searchByCode(companyId, productCode);
      } catch (error) {
        console.log(error);
      }
      if (responseData.data.length > 0) {
        if (responseData.data[0].status !== "1") {
          Swal.fire({
            icon: "warning",
            title: `ค้นหาสำเร็จ !`,
            text: `สินค้า Code : ${productCode} ยังไม่ได้รับการอนุมัติ`,
            confirmButtonText: "ตกลง",
          });
        } else {
          this.form = {
            ...this.form,
            ...responseData.data[0],
          };
        }
      } else {
        Swal.fire({
          icon: "error",
          title: `ค้นหาไม่สำเร็จ !`,
          text: `ไม่พบสินค้า Item no. : ${productCode}`,
          confirmButtonText: "ตกลง",
        });
      }
    },
    async searchByBarcode(companyId, productBarcode) {
      let responseData = [];
      try {
        responseData = await whApi.product.searchByBarcode(
          companyId,
          productBarcode
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.data.length > 0) {
        if (responseData.data[0].status !== "1") {
          Swal.fire({
            icon: "warning",
            title: `ค้นหาสำเร็จ !`,
            text: `สินค้า Barcode : ${productBarcode} ยังไม่ได้รับการอนุมัติ`,
            confirmButtonText: "ตกลง",
          });
        } else {
          this.form = {
            ...this.form,
            ...responseData.data[0],
          };
        }
      } else {
        Swal.fire({
          icon: "error",
          title: `ค้นหาไม่สำเร็จ !`,
          text: `ไม่พบสินค้า Barcode : ${productBarcode}`,
          confirmButtonText: "ตกลง",
        });
      }
    },
    clearForm() {
      this.searchProduct = "";
      this.form = {
        //new
        selectMainProduct: "",
        selectSubProduct: "",

        product_name: "",
        product_desc: "",
        product_barcode: "",
        product_pog: "",
        product_cost_price: "",
        product_wholesale_price: "",
        product_retail_price: "",
        product_promotion_price: "",
        product_min_stock: "",
        product_max_stock: "",
        product_bring_qty: "",
        status: "0",
        approved_by: "",
        product_expire: "",

        //old
        itemNo: "",
        itemDetail: "",
        barcode: "",
        accountingPeriod: "",
        docNumber: "",
        amount: "",
        pricePerUnit: "",
        discountPerUnit: "",
        vat: "",
        receiveDate: "",
        consignee: "",
        warehouseTo: "",
      };
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
